import React from "react"
import { withTrans } from "../withTrans"
import Layout from "../components/layout"
import TeamGrid from "../components/TeamGrid"
import HeroComponent from "../components/HeroComponent"
import { graphql } from "gatsby"
import SupportersGrid from "../components/SupportersGrid"

export const query = graphql`
  query($locale: [GRAPHCMS_Locale!]!) {
    gcms {
      aboutUsPages(locales: $locale) {
        title
        description
        sliderImages(locales: en) {
          url
          mimeType
        }
      }
      ourTeams(locales: $locale) {
        email
        jobTitle
        name
        phoneNumber
      }
      ourSupporters(locales: $locale) {
        name
      }
    }
  }
`

class aboutUs extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      aboutUsData: this.props.data.gcms.aboutUsPages[0],
      ourTeamData: this.props.data.gcms.ourTeams,
      ourSupportersData: this.props.data.gcms.ourSupporters,
    }
  }
  render() {
    const { t } = this.props
    return (
      <Layout>
        <div>
          <div className="font-futurastd_book text-center lg:grid lg:grid-rows-1 lg:justify-evenly lg:bg-title-dashed-bg lg:bg-no-repeat lg:bg-center mb-3 mx-3 lg:mx-7">
              <div className="text-center inline-block bg-white">
                <span className="text-2xl">&lt;</span>
                <span className="tracking-title-3 text-base ml-3 mr-2 uppercase">
                  {t("navbar_title_about_us")}
                </span>
                <span className="text-2xl">&gt;</span>
              </div>
          </div>
          <HeroComponent
            sliderItems={this.state.aboutUsData.sliderImages}
            pageData={this.state.aboutUsData}
          />

          <TeamGrid teamData={this.state.ourTeamData} />
          <SupportersGrid supportersData={this.state.ourSupportersData} />
        </div>
      </Layout>
    )
  }
}

export default withTrans(aboutUs)
