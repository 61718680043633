import React from "react"
import { withTrans } from "../withTrans"

const SupportersGrid = props => {
  return (
    <div className="mx-3 lg:mx-7">
      <p className="mt-4  mb-4 font-futurastd_heavy uppercase text-lg bg-title-dashed-bg bg-no-repeat bg-bottom lg:bg-title-dashed-bg lg:bg-no-repeat lg:bg-bottom">
        {props.t("title_our_supporters")}
      </p>
      <p className=" mb-4 font-futurastd_book">
        {props.t("text_support_statement")}
      </p>
      <div className="grid grid-col-1 lg:grid lg:grid-cols-3 gap-4 ">
        {props.supportersData.map((item, i) => (
          <div key={i}
            className={`${
              (i + 1) % 3 ? `rtl:border-l ltr:border-r border-none lg:border-dashed` : ``
            } my-2 border-dashed border-black`}
          >
            <div className="border-b mx-2 border-dashed border-black">
              <p className="font-futurastd_heavy uppercase mb-2">{item.name}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
export default withTrans(SupportersGrid)
