import React from "react"
import SliderComponent from "./SliderComponent"


const HeroComponent = props => {
  return (
    <div className="lg:grid lg:grid-cols-2 mx-3 lg:mx-7 gap-4 mb-4">
          <div>
            <SliderComponent
              sliderItems={props.sliderItems}
            ></SliderComponent>
          </div>
          <div>
            <p className="font-futurastd_heavy mb-3 uppercase text-lg lg:bg-title-dashed-bg lg:bg-no-repeat lg:bg-bottom">
              {props.pageData.title}
            </p>
            <p className="text-xs font-futurastd_book">
              {props.pageData.description}
            </p>
          </div>
        </div>
  )
}

export default HeroComponent
