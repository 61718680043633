import React from "react"
import { withTrans } from "../withTrans"


const TeamGrid = props => {
  return (
    <div className="mx-3 lg:mx-7">
      <p className="mb-4 font-futurastd_heavy uppercase text-lg  bg-title-dashed-bg bg-no-repeat bg-bottom lg:bg-title-dashed-bg lg:bg-no-repeat lg:bg-bottom ">
        {props.t("title_our_team")}
      </p>

      <div className="grid grid-col-1 lg:grid lg:grid-cols-3 gap-4 ">
        {props.teamData.map((item, i) => (
          <div key={i}
            className={`${
              (i + 1) % 3 ? `rtl:border-l ltr:border-r border-none lg:border-dashed` : ``
            } mb-4 border-dashed border-black`}
          >
            <div className="border-b mx-2 border-dashed border-black font-futurastd_book">
              <p className="font-futurastd_heavy uppercase inline"> {item.name} <span className="text-sm font-futurastd_book normal-case mx-1">{item.jobTitle}</span></p>
              <a
                href={"mailto:" + item.email}
                target="_blank"
                rel="noreferrer"
              >
                <p>{item.email}</p>
                </a>
              <a  href={"tel:" + item.phoneNumber}><p>{item.phoneNumber}</p></a>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
export default withTrans(TeamGrid)
